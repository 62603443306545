@import url('https://fonts.googleapis.com/css?family=Questrial');
@import url('https://fonts.googleapis.com/css?family=Pinyon+Script');
@import url('https://fonts.googleapis.com/css?family=Sorts+Mill+Goudy');
body{
    font-family: 游ゴシック体, 'Yu Gothic', YuGothic, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;  -webkit-text-size-adjust: none;
    // font-family:Meiryo, "メイリオ", 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'ＭＳ Ｐゴシック', 'MS PGothic', Helvetica, Arial, sans-serif;
}
a {
    transition: all 0.3s linear;

    &:hover {
        // opacity:0.8;
        color: #d77996;
        transition: all 0.3s linear;
        text-decoration:none;
    }
    &.btn-pink,&.btn-pink2{
        font-family: "ヒラギノ明朝 ProN", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝",sans-serif;
    }
}
ul {
    padding:0;
    li{
        padding:0;
        list-style:none;
    }
}
img{
    transition: all 0.3s linear;
}
.no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
}
.pc-visible {
  display: block;
}
.sp-visible {
  display: none;
}
.font-small{
    font-size:0.8rem;
}
.font-bold{
    font-weight:bold;
}
.font-cursive{
    // font-family:Pinyon Script, cursive;
    font-family: 'Sorts Mill Goudy', serif;
    font-size:0.8rem;
}
.font-mincho{
    font-family: "ヒラギノ明朝 ProN", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝",sans-serif;
}
.font-logo{
    font-family:Century Gothic,Questrial, sans-serif;
    line-height:1;
}
.font-red{
    color:#FF0000;
}
.font-disabled{
    color: #6c757d;
}
.font-link{
    color: #0000FF;
    text-decoration:underline;
}
.bg-gray{
    background-color: #F7F7F7;
    // background-image: url("../img/bg-main.png");
}
.bg-white{
    background-image: url("../img/bg-main.png");
    background-repeat:repeat;
    background-color: #fff7f7 !important;
    // background-color: rgba(164,113,87,0.2) !important;
    background-size: cover !important;
    background-attachment: fixed !important;
}

#pagetop{
  width: 50px;
  height: 50px;
  position: fixed;
  right: 0;
  bottom: 0;
  background: #d77996;
  opacity: 0.8;
  z-index:100;
}
#pagetop a{
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  text-decoration: none;
}
#pagetop a::before{
  font-family: "Font Awesome 5 Free";
  content: '\f106';
  font-weight:bold;
  font-size: 25px;
  color: #fff;
  position: absolute;
  width: 25px;
  height: 25px;
  top: 0;
  bottom: 10px;
  right: 0;
  left: 0;
  margin: auto;
  text-align: center;
}
.modal-body{
    max-height: 350px;
    overflow-y:auto;
}
#masthead {
    background: #FFF;
}
.is-animation {
    .navbar-brand {
        font-size: 2.2rem;
    }
    #reservation{
        transition: all 0.3s linear;
        img{
            width: 120px;
            transition: all 0.3s linear;
        }
    }
}
#masthead.is-animation .navbar-nav li a .menu-jp {
    display: none;
}
#reservation {
    font-size: 0.8rem;
    top: 8px;
    right: 0;
    z-index: 100;
    transition: all 0.3s linear;
    img {
        width:157px;
        transition: all 0.3s linear;
    }
}
#subtitle {
    font-size: 0.8rem;
    top: 2px;
    left: 0;
}
#snsicons{
    top: 2px;
    right: 0;
    z-index: 100;
}
.dropdown-toggle::after {
    display:none;
}
.navbar-nav {
    width:100%;
    text-align:center;

    li{
        width:16.66666666666667%;
        line-height: 1.1;
        &:first-child{
            // a{
                border-left:solid 1px #000;
            // }
        }
          border-right:solid 1px #000;

        a{
          // border-right:solid 1px #000;
          font-family:Century Gothic, sans-serif;
          transition: all 0.3s linear;

          .menu-jp{
            font-family: "ヒラギノ明朝 ProN", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝",sans-serif;
            font-size:0.7rem;
            color:#adb5bd;
          }
          &:hover{
            transition: all 0.3s linear;
          }

        }
    }
}
.maxwidth960{
    max-width:960px;
    width:98%;
    margin:0 auto;
}
#loader-bg {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background: #FFF;
    z-index: 1;
}
#loading {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
}
#hero{
    // height:700px;
    // height:580px;
    width:100%;
    padding-top: 124px;
    box-sizing: content-box;
}
.swiper-container {
    width: 100%;
    // height:500px;
    height: 100%;
}
.slide{
    width: 100%;
    height: 100%;
    // background-image:url('../img/main.jpg');
    // background-repeat:no-repeat;
    // background-size:cover;
    // background-position:center top;
}
.slide2{
    width: 100%;
    height: 100%;
    // background-image:url('../img/main2.jpg');
    // background-repeat:no-repeat;
    // background-size:cover;
    // background-position:center top;
}
.swiper-container2, .swiper-container3{
    overflow: hidden;
}
.thumbbox img{
    height: 100%;
    object-fit: cover;
}
figure {
    // width: 800px;
    // height: 300px;
    margin: 0;
    padding: 0;
    // background: #fff;
    overflow: hidden;
    position: relative;
}
figure::before {
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg);
}
figure:hover::before {
    -webkit-animation: shine .75s;
    animation: shine .75s;
}
@-webkit-keyframes shine {
    100% {
        left: 125%;
    }
}
@keyframes shine {
    100% {
        left: 125%;
    }
}
a.btn-pink {
    background-color: #D87997;
    padding: 10px 30px;
    border-radius: 30px;
    color: #FFFFFF;
    transition: all 0.3s linear;

    &:hover{
        // color: #FFFFFF;
        text-decoration: none;
        // content:"→";
        transition: all 0.3s linear;
        color: #FFFFFF;
        opacity:0.8;
        .hoveur{
            margin-left: 180px;
        }
    }
}
a.btn-pink2{
    background-color: #D87997;
    padding: 10px 40px;
    border-radius: 30px;
    color: #FFFFFF;
    transition: all 0.3s linear;
    &:hover{
        // color: #FFFFFF;
        text-decoration: none;
        // content:"→";
        transition: all 0.3s linear;
        color: #FFFFFF;
        opacity:0.8;
        .hoveur{
            margin-left: 180px;
        }
    }
    &:first-child{
        // margin-right:1.5rem;
    }
}
.hoveur {
    background: linear-gradient(rgba(255,255,255,255),rgba(204,204,204,204));
    width: 200px;
    height: 50px;
    margin-top: -50px;
    transform: rotate(40deg);
    margin-left: -180px;
    opacity: 0.3;
    transition: .5s;
}
.iframe_parent{
    position:relative;
    width:100%;
    height:0;
    padding-top:75%;
    overflow: hidden;
}
.iframe_parent iframe{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
}
.menu_tab{
    border-bottom: solid 5px #d87997;
    .no-gutters {
        margin-right: 0;
        margin-left: 0;

        > .col,
        > [class*="col-"] {
          padding-right: 5px;
          padding-left: 0;
        }
        .col-4{
            &:last-child{
                padding-right:0 !important;
            }
        }
    }
    .col-4{
        p{
            font-size: 1.3rem !important;
            background: #E7E7E7;
            margin: 0 !important;
            text-align: center;
            padding: 0.7rem 0;
            cursor: pointer;
            &.word_eyelash,&.word_nail,&.word_paragel,&.word_requirement,&.word_education,&.word_entry{
                &:before {
                    color:#d87997;
                    a{
                        color:#d87997;
                    }
                }
            }
            &.active{
                background: #d87997;
                &.word_eyelash,&.word_nail,&.word_paragel,&.word_requirement,&.word_education,&.word_entry{
                    &:before {
                        color:#FFF;
                        a{
                            color:#FFF;
                        }
                    }
                }
            }
            &.word_eyelash{
                &:before {
                    content: "EYELASH";
                    a{
                        color:#d87997;
                    }
                }
                &:hover{
                    &:before{
                        content: "アイラッシュ";
                    }
                }
            }
            &.word_nail{
                &:before {
                    content: "NAIL";
                }
                &:hover{
                    &:before{
                        content: "ネイル";
                    }
                }
            }
            &.word_paragel{
                &:before {
                    content: "PARAGEL";
                }
                &:hover{
                    &:before{
                        content: "パラジェル";
                    }
                }
            }
            &.word_requirement{
                &:before {
                    content: "REQUIREMENT";
                }
                &:hover{
                    &:before{
                        content: "募集要項";
                    }
                }
            }
            &.word_education{
                &:before {
                    content: "EDUCATION";
                }
                &:hover{
                    &:before{
                        content: "研修制度";
                    }
                }
            }
            &.word_entry{
                &:before {
                    content: "ENTRY";
                }
                &:hover{
                    &:before{
                        content: "採用エントリー";
                    }
                }
            }
        }
    }
}
#front-page {
    h2{
        text-align:center;
        color:#A58028;
        font-size:3rem;
        font-family:Pinyon Script, sans-serif;

        span{
            font-size:1.3rem;
            font-family: "ヒラギノ明朝 ProN", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝",sans-serif;
        }

        &:before,&:after {
            content: "";
            display: inline-block;
            max-width: 100px;
            width: 8%;
            height: 1px;
            margin: 0 2rem;
            background-color: #A58028;
            vertical-align: middle;
        }
    }
    .section{
        padding:70px 0;
    }
    #concept{
        h3{
            font-size:1.7rem;
            text-align:center;
            color:#D87997;
            margin-top:3rem;
            line-height:1.5;
        }
        h4{
            font-size:1.3rem;
            text-align:center;
            margin-top:3rem;
        }
        ul{
            padding:0;
            margin-top: 3rem;
            max-width: 590px;
            width:98%;
        }
        li{
            list-style: none;
            font-size:1.12rem;
            color:#555555;
            letter-spacing: -0.05rem;
            line-height: 1.6;
        }
        .col{
            p{
                font-size:0.8rem;
            }
        }
    }
    #paragel,#paraspa{
        // background-color:#F7F7F7;
        h3{
            font-size:1.7rem;
            text-align:center;
            color:#D87997;
            margin-top:3rem;
            line-height:1.5;
        }
        p{
            line-height:1.8;
        }
    }
    #menu{
        h3{
            font-family: "ヒラギノ明朝 ProN", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝",sans-serif;
            font-size:1.5rem;
            margin-top:1.5rem;
        }
        p{
            font-family: "ヒラギノ明朝 ProN", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝",sans-serif;
            color:#A58028;
        }
    }
    #salon{
        h3{
            font-size:1.5rem;
            margin-top:1.5rem;
            text-align:center;
            span{
                border-bottom: 1px solid #A7A7A7;
                display: inline-block;
                width: 100px;
                line-height: 1;
                height: 20px;
            }
        }
        figure {
            img {
                width:100%;
                height:auto;
            }
        }
        h4{
            text-align:center;
            font-size:0.8rem;
            font-weight:bold;
            // margin-top: 1rem;
            padding: 1rem 0;
        }
    }
    #recruit{
        h3{
            font-size:1.3rem;
            margin-top:1.5rem;
            text-align:center;
            letter-spacing: 0.1rem;
        }
    }
    #instagram{
        .thumbbox{
            img{
                height:100%;
                object-fit:cover;
            }
        }
    }
}
footer {
    background: #F7F7F7;
    // padding: 4rem 0 0 0;
    h4{
        font-size:1.2rem;
    }
    i{
        color:#d77996;
    }
    ul{
        li{
            line-height:2.2;

            a{
                // line-height:2;
                font-size:0.9rem;
                img{
                    // vertical-align:sub;
                }
            }
        }
    }
    p{
        font-size:1.1rem;
        // font-size:0.9rem;
    }
    .site-info{
        text-align: center;
        background-color: #d77996;
        color: #FFF;
        padding: 0.5rem 0;
        margin-top: 2rem;
    }
}

////////////////////////
//   Salon-list.html  //
////////////////////////
#main {
    // background-size: cover;
    background-size: contain;
    width: 100%;
    height: 400px;
    background-image: url("../img/bg-main.png");
    background-repeat:repeat;
    background-color: #e9e9e9;
    position: relative;
}
.title_area {
    h1{
        font-family:Pinyon Script, sans-serif;
        font-size:3rem;
        .font-jp{
            font-size: 1.3rem;
            font-family: "ヒラギノ明朝 ProN", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝",sans-serif;
        }
    }
    position: absolute;
    left: 0;
    width: 100%;
    // height: 120px;
    top: 55%;
    text-align: center;
}
#page{
    h1 {
        &:before, &:after {
            content: "";
            display: inline-block;
            max-width: 100px;
            width: 8%;
            height: 1px;
            margin: 0 2rem;
            background-color: #222222;
            vertical-align: middle;
        }
    }
    #salon{
        h3{
            font-size:1.5rem;
            margin-top:1.5rem;
            text-align:center;
            span{
                border-bottom: 1px solid #A7A7A7;
                display: inline-block;
                width: 100px;
                line-height: 1;
                height: 20px;
            }
        }
        figure {
            img {
                width:100%;
                height:auto;
            }
        }
        h4{
            text-align:center;
            font-size:0.8rem;
            font-weight:bold;
            // margin-top: 1rem;
            padding: 1rem 0;
        }
    }
}
////////////////////////
//   Salon-info.html  //
////////////////////////
.blank {
    background-color: #D87997;
    color: #FFF;
    line-height: 2;
    padding: 0.3rem 0.5rem;
    &:hover{
        color: #FFF;
        opacity:0.8;
    }
}
.info-box {
    border: dotted 1px #aaaaaa;
    padding: 1rem;
}
#saloninfo{
    h2{
        font-size: 1.6rem;
        color: #a58028;
        border-bottom: #a58028 solid 1px;
        margin-bottom: 1.5rem;
        span{
            font-size:1rem;
        }
    }
    h3{
        font-size: 1.4rem;
    }
    .side-bar{
        h4{
            font-size: 1.3rem;
            border-bottom: #222222 solid 1px;
            margin-bottom: 1.5rem;
            line-height: 1.5;
        }
        h5{
            font-size: 1.2rem;
            font-weight: bold;
        }
        h6{
            font-size: 1.1rem;
            // cursor: pointer;
            // &:after {
            //     // content: "　△";
            //     // transform: rotate(0deg);
            //     transition: all 0.3s linear;
            // }
        }
        // h6.active{
        //     &:after {
        //         content: "　▽";
        //         // transform: rotate(-180deg);
        //         transition: all 0.3s linear;
        //     }
        // }
        #nail-sendai-child,#eyelash-sendai-child{
            // display:none;
            li{
                line-height: 2;
                padding-left: 1rem;
                font-weight: bold;
            }
        }
        #sapporo-child{
            li{
                line-height: 2;
                padding-left: 1rem;
                font-weight: bold;
            }
        }
    }
    .no-gutters {
        margin-right: 0;
        margin-left: 0;

        > .col,
        > [class*="col-"] {
          padding-right: 10px;
          padding-left: 0;
        }
        .col{
            &:last-child{
                padding-right:0;
            }
        }
    }
    .staff-section figcaption {
        line-height: 1.2;
    }
    .access{
        position:relative;
        width:100%;
        height:0;
        padding-top:75%;
        overflow: hidden;
    }
    .access iframe{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
    }
}

//////////////////
//   Menu.html  //
//////////////////
#menupage{
    h2{
        font-size: 1.6rem;
        margin-bottom: 1.5rem;
        color: #a58028;
        border-bottom: #a58028 solid 1px;
        span{
            font-size:1rem;
        }
    }
    h3{
        font-size: 1.2rem;
        border-bottom: solid 1px #a7a7a7;
    }
    h4{
        font-size: 1rem;
        font-weight:bold;
    }
    p{
        font-size:0.9rem;
    }
    .no-gutters {
        margin-right: 0;
        margin-left: 0;

        > .col,
        > [class*="col-"] {
          padding-right: 10px;
          padding-left: 0;
        }
        .col{
            &:last-child{
                padding-right:0;
            }
        }
    }
}
/////////////////////
//   Gallery.html  //
/////////////////////
#gallery {
    .col-md-2 {
        margin-top: 1.5rem;
    }
    .list-inline-item {
        padding: 0 1rem;
        &:hover{
            color:#D87997;
            cursor:pointer;
        }
        &.active{
            color:#D87997;
        }
    }
    #animationList {
        overflow: hidden;
        li {
              width: 220px;
              height: 220px;
              padding: 10px;
              float: left;
              color: #222;
              span {
                  display: block;
                  width: 180px;
                  height: 180px;
                  padding: 20px;
            }
        }
    }
}
/////////////////////
//   Recruit.html  //
/////////////////////
#recruitpage{
    h3{
        font-size: 1.3rem;
    }
    .recruitsection{
        display:none;
        h2{
            font-size: 1.6rem;
            color: #a58028;
            border-bottom: #a58028 solid 1px;
            margin-bottom: 1.5rem;
            span{
                font-size:1rem;
            }
        }
        h3{
            font-size: 1.2rem;
            font-weight:bold;
            margin-top:2rem;
        }
        h4{
            font-size: 1rem;
            font-weight:bold;
            margin-top:1rem;
        }
        p{
            font-size: 1rem;
            // font-size:0.9rem;
        }
        .form-title{
            font-size: 1rem;
            font-weight:bold;
            margin-bottom: 0.5rem;
        }
        .form-check-inline{
            label{
                cursor: pointer;
                display: inline-block;
            }
        }
        .btn-pink{
            color:#FFF;
            transition: all 0.3s linear;
            &:hover {
                color: #fff;
                background-color: #D87997;
                border-color: #D87997;
                opacity:0.8;
                transition: all 0.3s linear;
            }
        }
    }
    .no-gutters {
        margin-right: 0;
        margin-left: 0;

        > .col,
        > [class*="col-"] {
          padding-right: 10px;
          padding-left: 0;
        }
        .col{
            &:last-child{
                padding-right:0;
            }
        }
    }
}
/***********************
     Specials
***********************/
#specials{
    h2{
        font-size: 1.6rem;
        margin-bottom: 1.5rem;
        color: #a58028;
        border-bottom: #a58028 solid 1px;
        span{
            font-size:1rem;
        }
    }
    h3{
        // font-size: 1.2rem;
        // border-bottom: solid 1px #a7a7a7;
        font-size: 1rem;
        display: inline-block;
        background: #D87997;
        color: #FFF;
        padding: 0.2rem 1rem;
    }
    h4{
        font-size: 1rem;
        font-weight:bold;
    }

    p{
        font-size:1rem;
        &.copy {
            color: #D87997;
            font-family: "ヒラギノ明朝 ProN", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝",sans-serif;
            font-size: 1.5rem;
            font-weight: bold;
            line-height: 1.6em;
            text-shadow: 0 0 3px #ffffff;
            margin-bottom: 20px;
            padding-top: 1.5rem;
        }
    }
    #special-paragel{
        .topic {
            min-height: 280px;
            background: url(../img/outline-nail.jpg) no-repeat scroll right 0;
            background-size: contain;
        }
    }
    #special-eyelash{
        .topic {
            min-height: 200px;
            background: transparent url(../img/outline-eyelush.jpg) no-repeat scroll right 0;
            background-size: contain;
        }
        .eyelush-all {
            width: 100%;
            overflow: hidden;
            tr{
                text-align: center;
                td {
                    width: 50%;
                    background: #ffffff;
                    color: #000;
                    font-size: 0.9em;
                    line-height: 1.4em;
                    text-align: center;
                    padding: 20px;
                    overflow: hidden;
                }
                &.caption{
                    th {
                        height: 30px;
                        background: #f6f6f6;
                        color: #a58028;
                        font-size: 1em;
                        text-align: center;
                        padding: 0.5rem;
                    }
                }
            }
        }
    }
    .gelchart-all {
        margin-top: -120px;
        overflow: hidden;
        .subcopy {
            font-size: 1.2em;
            font-weight: bold;
            margin-bottom: 20px;
        }
       .tit {
            font-size: 1.6em;
            font-weight: bold;
            line-height: 1.4em;
            margin-bottom: 20px;
            span.blue {
                background: #c7eafc;
                padding: 4px 6px;
            }
            span.pink {
                background: #fbdfeb;
                padding: 4px 6px;
            }
        }
        .gelchart{
            padding-bottom: 20px;
            overflow: hidden;
            .left,.right {
                float: left;
                width: 50%;
            }
            dl {
                dt{
                    &.para-pc-exp {
                        font-size: 1.5rem;
                        font-family: "ヒラギノ明朝 ProN", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝",sans-serif;
                        line-height: 1.4em;
                        color: #D87997;
                        display: block;
                        padding: 10px 0 20px;
                        text-align: center;
                    }
                }
            }
        }
    }
    .faq-list{
        dl{
            margin-bottom: 1.5rem;
        }
        dt {
            font-weight: 700;
            font-size: 1.2rem;
            padding-left: 2rem;
            text-indent: -2rem;
            &:before{
                content: "Q　";
                font-size: 1.2rem;
                font-weight: normal;
            }
        }
        dd{
            // font-weight: 700;
            // font-size: 1.2rem;
            padding-left: 2rem;
            text-indent: -2rem;
            margin-top: 5px;
            &:before{
                content: "A　";
                font-size: 1.2rem;
            }
        }
    }
    .rules {
        .statement {
            font-weight: bold;
            margin-bottom: 15px;
        }
        .each-rule {
            ul{
                li{
                    line-height: 1.2em;
                    margin-bottom: 10px;
                    padding-left: 1em;
                    text-indent: -1em;
                    list-style: none outside none;
                    &:before {
                        content: "・";
                        color: #D87997;
                    }
                    ul {
                        li {
                            font-size: 0.9em;
                            padding-left: 1em;
                            text-indent: -1em;
                        }
                    }
                }
            }
        }
    }
}
/***********************
     Privacy policy
***********************/
#privacy {
    p,li{
        line-height:1.8;
    }
    a{
        text-decoration: underline;
    }
}

/***********************
     SP screen
***********************/
@media screen and (max-width : 991px) {
    .pc-visible {
        display: none !important;
    }
    .sp-visible {
        display: block;
    }
    #pagetop {
        right: 10px;
    }
    #masthead{
        .position-absolute{
            position:relative !important;
        }
        .text-center{
            text-align:left !important;
        }
        .navbar {
            position: absolute;
            left: 0;
            top: 0;
            width:100%;
        }
        .navbar-brand {
            font-size: 2.2rem;
        }
        .navbar-toggler {
            position: absolute;
            right: 10px;
            top: 10px;
            transition: .25s;
            z-index: 20000;
            cursor: pointer;
            display: block;
            width: 50px;
            height: 45px;
            > span{
                position: absolute;
                left: 0;
                right: 0;
                // width: 18px;
                width: 25px;
                height: 2px;
                margin: 0 auto;
                background: #000;
                border-radius: 2px;
                transition: .25s;
                &:nth-child(1) {
                    top: 15px;
                }
                &:nth-child(2) {
                    top: 21px;
                }
                &:nth-child(3) {
                    top: 27px;
                }
            }
            &.on{
                > span{
                    &:nth-child(1) {
                        top: 21px;
                        transform: rotate(45deg);
                    }
                    &:nth-child(2) {
                        opacity: 0;
                        transform: rotate(90deg);
                    }
                    &:nth-child(3) {
                        top: 21px;
                        transform: rotate(135deg);
                    }
                }
            }
        }
        .navbar-nav {
            background-color:#FFF;
            li {
                width: 100%;
                border:none !important;
                padding: 0.8rem 0;
                &:first-child {
                    border:none !important;
                }
            }
        }
        .navbar-collapse {
            position: fixed;
            width: 100%;
            top: 70px;
        }
        &.pb-3{
            padding-bottom:0 !important;
        }
    }
    .menu_tab{
        .col-4{
            p{
                font-size: 1rem !important;
                &.word_requirement{
                    &:before {
                        content: "REQUIREMENT";
                        font-size:0.8rem;
                    }
                    &:hover{
                        &:before{
                            content: "募集要項";
                        }
                    }
                }
                &.word_education{
                    &:before {
                        content: "EDUCATION";
                        font-size:0.8rem;
                    }
                    &:hover{
                        &:before{
                            content: "研修制度";
                        }
                    }
                }
                &.word_entry{
                    &:before {
                        content: "ENTRY";
                        font-size:0.8rem;
                    }
                    &:hover{
                        &:before{
                            content: "採用エントリー";
                        }
                    }
                }
            }
        }
    }
    footer {
        .col-md-4 {
            margin-top: 3rem;
        }
    }
    #front-page{
        #hero{
            padding-top: 77px;
        }
        h2{
            font-size: 2rem;
            span{
                font-size:0.8rem;
            }

            &:before,&:after {
                max-width: 100px;
                width: 5%;
                height: 1px;
                margin: 0 1rem;
            }
        }
        #concept,#paragel {
            h3 {
                font-size: 1.3rem;
            }
            h4 {
                font-size: 1.2rem;
            }
        }
        .mt-5 {
            margin-top: 2rem !important;
        }
        .col-md-4 {
            margin-top: 2rem;
        }
        #menu {
            h3 {
                font-size: 1.15rem;
            }
            .col-md-4{
                &:nth-child(2) {
                    margin-top: 5rem;
                }
            }
        }
        #salon{
            // .col-md-3 {
            //     margin-top: 2rem !important;
            // }
        }
        #recruit {
            h3 {
                font-size: 1.15rem;
            }
        }
    }
    #page{
        h1 {
            font-size: 2rem;
            &:before, &:after {
                max-width: 100px;
                width: 5%;
                height: 1px;
                margin: 0 1rem;
            }
            .font-jp {
                font-size: 1.15rem;
            }
        }
        .breadcrumb {
            // padding-left: 0;
            display:none;
        }
        #main {
            height: 200px;
            .title_area {
                top: 60%;
            }
        }
        #salon{
            .mt-5{
                margin-top:0 !important;
                &.sp_active{
                    margin-top:3rem !important;
                }
            }
            .col-md-3 {
                margin-top: 2rem !important;
            }
        }
        #saloninfo{
            .side-bar {
                // text-align: center;
                border-bottom: #222222 solid 1px;
                margin-bottom: 3rem;
                li {
                    padding-left: 0;
                }
            }
            h2 {
                font-size: 1.3rem;
            }
        }
        #menupage{
            .side-bar {
                // display: none;
            }
            h2 {
                font-size: 1.3rem;
                margin-bottom: 0;
            }
            #sendai-eyelash,#sendai-paraspa,#sapporo-nail,#sapporo-eyelash,#sapporo-paraspa {
                display: block !important;
                margin-top: -6rem !important;
            }
            h3 {
                margin-top: 1.5rem;
            }
        }
        #gallery {
            #animationList {
                li {
                    width: 165px;
                    height: 165px;
                    padding: 10px;
                    float: left;
                    color: #222;
                }
            }
            .list-inline-item {
                width: 100%;
                padding: 0.3rem;
                margin: 0;
            }
        }
        #recruitpage{
            .recruitsection{
                // display:block;
            }
            #education,#entry {
                // margin-top: 3rem;
            }
        }
        #specials{
            p{
                &.copy {
                    font-size: 1.2rem;
                }
            }
        }
    }
}
@media screen and (max-width : 767px) {
    #page{
        .gelchart-all {
           .tit {
                font-size: 1.0rem;
                span.blue {
                }
                span.pink {
                }
            }
            .gelchart{
                .subcopy {
                }
                dl {
                    dt{
                        &.para-pc-exp {
                            font-size: 120%;
                            line-height: 1.4em;
                            padding: 10px 0 20px;
                        }
                    }
                }
                .left,.right {
                    float: none;
                    width: 100%;
                }
            }
        }
    }
}